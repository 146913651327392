@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Circular Std', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Scroll to anchor links smoothly */
html {
  scroll-behavior: smooth;
}

/* For Chrome, Safari, and Opera */
::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.scrollbar-hide {
  scrollbar-width: none;
}

/* For Internet Explorer and Edge */
.scrollbar-hide {
  -ms-overflow-style: none;
}

/* Hide default `Choose File` label */
.image-upload:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.listingImage input[type="file"] {
  transition: 200ms;
}

/* Dim images */
.listingImage:hover:not(button) input[type="file"] {
  opacity: 0.5;
}

/* Spin */
.animate-submit-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Float up and down */
.ghost-float-top {
  animation-name: float-top;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.ghost-float-bottom {
  animation-name: float-bottom;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes float-top {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(7px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes float-bottom {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0);
  }
}


.hostU-rotating-gradient {
  text-align: center;
  background-image: linear-gradient(
    -90deg,
    #4BB5E2 0%,
    #2EBDB5 50%,
    #4BB5E2 100%
  );
  background-size: 400% auto;
  animation: shine 5s linear infinite;
}

@keyframes shine {
  from {
    background-position: 400% center;
  }
  to {
    background-position: 267% center;
  }
}


/* Navbar Hamburger Menu */
.burger-menu.is-active div:nth-child(1) {
  transform: rotate(-45deg) translate(-8px, 6px);
}

.burger-menu.is-active div:nth-child(2) {
  opacity: 0;
}

.burger-menu.is-active div:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -3px);
}

.slick-list {
  z-index: 40;
}

.match-list .slick-track {
  display: flex;
  align-items: center;
}

.listing-arrow {
  opacity: 0;
}

.match-list:hover .listing-arrow {
  opacity: 1;
}

/* Remove arrows from number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
